@charset "UTF-8";
.header-container {
  background-color: #fff;
}

.main_image h2 {
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 2, 0.8));
}

a {
  word-wrap: break-word;
  word-break: break-all;
}

nav ul.pc {
  margin: 0;
}

nav ul.pc li .megaMenu {
  display: none;
  position: absolute;
  top: 86px;
  height: 86px;
  background-color: #eee;
  width: 100%;
  left: 0;
  border-top: 2px dotted #000;
  z-index: 1000;
}

nav ul.pc li .megaMenu ul {
  max-width: 900px;
  margin: auto;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

nav ul.pc li .megaMenu ul a {
  white-space: nowrap;
}

nav ul.pc li a {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86px;
  box-sizing: border-box;
  border: 2px dotted transparent;
}

@media only screen and (max-width: 768px) {
  .header-container {
    position: relative;
    z-index: 110;
    background-color: #fff;
  }
  nav ul.pc {
    flex-wrap: nowrap;
  }
  nav ul.pc li {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 639px) {
  .top.main_image {
    z-index: 90;
  }
  header {
    height: auto;
  }
  .header-container {
    background-color: #fff;
  }
}

.topMessage {
  text-align: center;
  margin: 0 auto 100px;
  background-image: url(/img/top_bg.jpg);
  background-size: cover;
  background-position: center center;
  padding: 100px 0;
}

.topMessage h3 {
  font-size: 30px;
}

.topMessage-inner {
  background-color: #fff;
  padding: 30px 40px 50px;
  max-width: 900px;
  margin: auto;
}

.topWapper.main {
  padding: 50px 0 100px;
}

.topWapper .topContents {
  margin: 0 auto 100px;
}

.topWapper .topContents-item p {
  text-align: center;
  font-size: 20px;
}

.topWapper .topContents-item img {
  display: block;
  margin: auto;
  text-align: center;
}

.topWapper .topContents-item:nth-of-type(1) img {
  width: 100px;
}

.topWapper .topContents-item:nth-of-type(2) {
  transition-delay: 0.1s;
}

.topWapper .topContents-item:nth-of-type(2) img {
  width: 60px;
}

.topWapper .topContents-item:nth-of-type(3) {
  transition-delay: 0.2s;
}

.topWapper .topContents-item:nth-of-type(3) img {
  width: 100px;
}

.topWapper .topContents-item:nth-of-type(4) {
  transition-delay: 0.3s;
}

.topWapper .topContents-item:nth-of-type(4) img {
  width: 100px;
}

.topWapper .topContents-item:nth-of-type(5) {
  transition-delay: 0.4s;
}

.topWapper .topContents-item:nth-of-type(5) img {
  width: 100px;
}

.topWapper .topContents-item:nth-of-type(6) {
  transition-delay: 0.5s;
}

.topWapper .topContents-item:nth-of-type(6) img {
  width: 100px;
}

article.news {
  max-width: none;
  background-color: #eee;
  padding: 120px 20px 170px;
  margin: 0;
}

article.news section {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.topContents-link-wrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.topContents-link {
  width: 300px;
  position: relative;
  overflow: hidden;
  border: 1px solid #000;
  margin: 0 auto 15px;
}

.topContents-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  background: #000;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
  z-index: 0;
}

.topContents-link:hover a {
  color: #fff;
}

.topContents-link:hover a::after {
  color: #fff;
}

.topContents-link:hover::before {
  transform: translateX(0%);
  z-index: 0;
}

.topContents-link a {
  transition: 0.4s;
  font-size: 20px;
  text-decoration: none;
  display: block;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  z-index: 1;
}

.topContents-link a::after {
  content: '>>';
  position: absolute;
  top: 28%;
  right: 20px;
  color: #000;
  transition: 0.4s ease;
}

.sp-hide {
  display: block;
}

.pc-hide {
  display: none;
}

.topBanner {
  padding: 80px 0;
  background: #fff;
}

.topBanner-list {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.topBanner-item {
  width: 400px;
  list-style: none;
}

.topBanner-item:nth-of-type(1) {
  margin: 0 6% 0 0;
}

.topBanner-item img {
  width: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  article {
    margin-bottom: 3em;
  }
  .sp-hide {
    display: none;
  }
  .pc-hide {
    display: block;
  }
  article.news {
    padding: 30px 20px 100px;
  }
  .topMessage-inner {
    padding: 20px;
  }
  .topMessage-inner p {
    text-align: left;
  }
  .topMessage {
    margin: 0 0 0;
    padding: 40px 20px;
  }
  .topMessage h3 {
    font-size: 20px;
  }
  .topContents-link-wrap {
    flex-direction: column;
  }
  .topContents-link a {
    padding: 5px;
  }
  .topContents-link a::after {
    top: 10%;
  }
  .topWapper.main {
    padding: 50px 0 50px;
  }
  .home .topContents {
    flex-wrap: wrap;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    margin: 0 0 15px;
  }
  .topContents-item {
    width: 47.5%;
    margin: 0 3% 20px 0;
  }
  .topContents-item:nth-of-type(2n) {
    margin: 0 0 20px;
  }
  .main.wrapper {
    margin: 0 auto 60px;
  }
  .topContents-link {
    width: 90%;
  }
  .topContents-link a {
    font-size: 15px;
  }
  .topBanner {
    padding: 40px 20px;
  }
  .topBanner-list {
    flex-direction: column;
  }
  .topBanner-item {
    width: 100%;
  }
  .topBanner-item:nth-of-type(1) {
    margin: 0 0 20px 0;
  }
}

.breadcrumb {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  max-width: 900px;
  padding: 0 0 0 0;
  margin: 30px auto 30px;
  opacity: 1;
  flex-wrap: wrap;
}

.breadcrumbItem {
  list-style: none;
  color: #000 !important;
  font-size: 16px;
  margin: 0 20px 0 0;
}

.breadcrumbItem a {
  visibility: visible;
  color: #000 !important;
  position: relative;
  transition: 0.4s ease;
  text-decoration: underline;
}

.breadcrumbItem a:after {
  content: '＞';
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
}

.breadcrumbItem a:hover {
  text-decoration: none;
  opacity: 0.7;
}

@media only screen and (max-width: 768px) {
  .breadcrumb {
    margin: 15px auto 15px 20px;
  }
  .breadcrumbItem {
    font-size: 12px;
  }
}

.under.main_image {
  height: 200px;
  position: relative;
}

.ta-c {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .top.main_image {
    height: 255px;
  }
  .main_image h2 {
    font-size: 18px;
    font-weight: bold;
  }
  .top.main_image h2 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 639px) {
  .bgCrossFade {
    margin-top: -240px !important;
    height: 240px !important;
  }
}

.printing.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/printing_main.png);
}

.businessContentsImg {
  width: 30%;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .businessContentsImg {
    width: 60%;
  }
  article.businessContents {
    padding: 0;
  }
}

.contents.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/contents_main.jpg);
}

.contentsRead {
  text-align: center;
  max-width: 900px;
  margin: auto;
}

.contentsConsultation {
  margin: 0px 0 40px;
}

.contentsConsultation p {
  text-align: center;
  font-size: 20px;
}

.contents.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/contents_main.jpg);
}

.contents .contentsRead {
  text-align: center;
}

.contents .contentsConsultation {
  margin: 0px 0 40px;
}

.contents .contentsConsultation p {
  text-align: center;
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .page .main-container {
    padding: 0 20px;
  }
  .page-id-21 .main-container {
    padding: 0;
  }
  .contentsRead {
    margin: 0 0 20px;
    text-align: left;
  }
  .contents .topContents-link {
    margin: 60px auto 15px;
  }
  .home .topContents section.nofadeIn {
    margin-top: 0;
  }
}

.video.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/video_main.jpg);
}

.videoWrap {
  margin: 60px 0 120px;
}

.videoWrap h3 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.videoWrap ul {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
}

.videoWrap li {
  list-style: none;
  margin: 0 60px 0 0;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videoWrap li p {
  font-weight: bold;
  margin-top: auto;
}

.videoWrap li img {
  margin-top: auto;
  width: 200px;
}

.videoWrap li:nth-of-type(1) img {
  width: 140px;
}

.videoWrap li:nth-of-type(2) img {
  width: 150px;
}

.videoWrap li:nth-of-type(3) img {
  width: 140px;
}

.videoWrap li:nth-of-type(4) {
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .videoWrap ul {
    flex-wrap: wrap;
  }
  .videoWrap ul li {
    width: 47.5%;
    margin: 0 5% 30px auto !important;
  }
  .videoWrap ul li:nth-of-type(2n) {
    margin: 0 auto 30px auto !important;
  }
  .videoWrap ul li img {
    width: 100% !important;
  }
}

.achievement.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/achievement_main.png);
}

.topContentsMovie {
  position: relative;
  padding: 0 0 66.5%;
}

.topContentsMovie iframe {
  height: 100%;
  width: 100%;
  position: absolute;
}

.access.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/access_main.png);
}

.page .accessBlock {
  border-bottom: 2px dotted #666;
  padding: 0 0 40px;
  margin: 0 0 40px;
}

.page .accessBlock:nth-of-type(2) {
  border: none;
}

.page .accessBlock p {
  margin: 40px 0 0;
}

.page .accessBlock-inner {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
}

.page .accessImg {
  margin: 0 60px 0 0;
}

.page .accessImg img {
  vertical-align: top;
  width: 100%;
  max-width: 100%;
  margin: 0 0 30px;
}

.page .accessImg p {
  margin: 0;
  font-weight: bold;
  font-size: 18px;
}

.page .accessMap {
  min-width: 400px;
}

.page .accessMap img {
  vertical-align: top;
  width: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .page .accessBlock p {
    margin: 0;
  }
  .page .accessBlock-inner {
    flex-direction: column;
  }
  .page .accessMap {
    order: 1;
    min-width: auto;
  }
  .page .accessImg {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    order: 2;
    margin: 30px 0 0;
    justify-content: space-between;
  }
  .page .accessImg p {
    font-size: 14px;
    margin: 0 0 10px;
  }
  .page .accessImg div {
    width: 50%;
  }
  .page .accessImg div:nth-of-type(1) {
    margin: 0 20px 0 0;
  }
}

.web.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/web_main.png);
}

.web.under.main_image h2 {
  line-height: 1.5;
  text-align: center;
  writing-mode: horizontal-tb;
}

.webItem {
  border-bottom: 2px dotted #000;
  padding: 0 0 40px;
  margin: 0 0 50px;
}

.webItem:last-of-type {
  border: none;
}

.webimg {
  margin: 0 0 20px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .webimg img {
    width: 100%;
    max-width: 100%;
  }
}

.edit.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/edit_main.jpg);
}

.editBlock {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  padding: 0 0 40px;
  margin: 0 0 50px;
  border-bottom: 2px dotted #000;
}

.editBlock:last-of-type {
  border: none;
}

.editText {
  margin: 0 40px 0 0;
}

.editText h3 {
  margin-top: 0;
}

.editImg {
  width: 250px;
}

.editImg img {
  width: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .editBlock {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .editText {
    margin: 20px 0 0;
  }
}

.plan.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/plan_main.jpg);
}

.plan .topContents {
  flex-direction: column;
}

.planItem {
  text-align: center;
  margin: 0 auto 40px;
}

.planItem p {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.planItem:last-of-type {
  margin: 0 auto 120px;
  position: relative;
}

.planItem:last-of-type::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -85px;
  transform: translateX(-50%);
  width: 120px;
  height: 60px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/plan_arrow.png);
}

.planList {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  margin: 0 0 60px;
}

.planList li {
  font-size: 18px;
  list-style: none;
  margin: 0 20px 0 0;
}

.planList li:last-of-type {
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .planItem img {
    width: 100%;
    max-width: 100%;
  }
  .plan .breadcrumb {
    margin: 15px auto 15px 0px;
  }
  .planList {
    flex-wrap: wrap;
  }
  .planList li {
    text-align: center;
    width: 47.5%;
    margin: 0 2% 20px 0;
  }
  .planList li:nth-of-type(2n) {
    margin: 0 0 20px;
  }
}

article.aboutus {
  margin-bottom: 60px;
}

.aboutus .txarea {
  line-height: 1.8;
}

.contact.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/contact_main.jpg);
  background-position: center;
}

.contactBlock {
  max-width: 900px;
  width: 100%;
  margin: auto;
}

.contactBlock dl {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px dashed #c8c8c8;
}

.contactBlock dt {
  padding: 10px 0 0;
  min-width: 200px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.contactBlock dt span {
  color: #be2c2c;
  border: 1px solid #be2c2c;
  padding: 1px 3px;
  border-radius: 2px;
}

.contactBlock dd {
  width: 100%;
}

.contactBlock input[type="text"],
.contactBlock input[type="tel"],
.contactBlock input[type="email"],
.contactBlock textarea {
  font-family: "Ryumin Medium KL", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif !important;
  transition: 0.4s ease;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
}

.contactBlock input[type="text"]:placeholder-shown,
.contactBlock input[type="tel"]:placeholder-shown,
.contactBlock input[type="email"]:placeholder-shown,
.contactBlock textarea:placeholder-shown {
  background-color: #e7e7e7;
  border: 1px solid #e7e7e7;
}

.contactBlock input[type="text"]:focus,
.contactBlock input[type="tel"]:focus,
.contactBlock input[type="email"]:focus,
.contactBlock textarea:focus {
  background-color: #fff;
  border: 1px solid #e7e7e7;
}

.contactBlock textarea {
  min-height: 300px;
}

.post-wrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
}

.post-wrap input[type="text"] {
  width: 40%;
  margin: 0 0 0 10px;
}

.post-wrap a {
  white-space: nowrap;
  position: relative;
  left: -190px;
  display: block;
  margin: 0 0 0 10px;
  padding: 3px 5px;
  color: #fff;
  border: none;
  border-radius: 5px;
  background-color: #be2c2c;
}

.todoufuken-wrap select {
  color: #666;
  transition: 0.4s ease;
  width: 200px;
  padding: 10px;
  border-radius: 5px;
  background-color: #e7e7e7;
  border: 1px solid #e7e7e7;
}

.todoufuken-wrap select option:nth-of-type(1) {
  color: #aaa;
}

.check-wrap .wpcf7-list-item,
.contact-thanks .wpcf7-list-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: flex-start;
  margin: 0 20px 20px 0;
}

.check-wrap label span,
.contact-thanks label span {
  position: relative;
  padding: 0 0 0 30px;
}

.check-wrap label span::before,
.contact-thanks label span::before {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  background-color: #e7e7e7;
  border: 1px solid #e7e7e7;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  transition: 0.4s ease;
}

.check-wrap label span::after,
.contact-thanks label span::after {
  transition: 0.4s ease;
  content: '';
  top: 3px;
  left: 3px;
  position: absolute;
  border-left: 2px solid #be2c2c;
  border-bottom: 2px solid #be2c2c;
  width: 12px;
  height: 8px;
  transform: rotate(-45deg);
  opacity: 0;
}

.check-wrap label input[type="checkbox"],
.contact-thanks label input[type="checkbox"] {
  color: #000;
  display: none;
}

.check-wrap label input[type="checkbox"]:checked + span::before,
.contact-thanks label input[type="checkbox"]:checked + span::before {
  background-color: #fff;
  border: 1px solid #e7e7e7;
}

.check-wrap label input[type="checkbox"]:checked + span::after,
.contact-thanks label input[type="checkbox"]:checked + span::after {
  opacity: 1;
}

.check-wrap label span::before {
  content: '';
  top: -3px;
  left: 0;
  position: absolute;
  background-color: #e7e7e7;
  border: 1px solid #e7e7e7;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  transition: 0.4s ease;
}

.check-wrap label span::after {
  transition: 0.4s ease;
  content: '';
  top: 0px;
  left: 3px;
  position: absolute;
  border-left: 2px solid #be2c2c;
  border-bottom: 2px solid #be2c2c;
  width: 12px;
  height: 8px;
  transform: rotate(-45deg);
  opacity: 0;
}

.contact-thanks {
  margin: 60px 0;
}

.contact-thanks label {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-submit {
  width: 300px;
  position: relative;
  overflow: hidden;
  margin: 0 auto 60px;
}

.contact-submit input[type="submit"] {
  border: 1px solid #000;
  opacity: .2;
  pointer-events: none;
  transition: 0.4s;
  width: 100%;
  appearance: none;
  font-size: 20px;
  text-decoration: none;
  display: block;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  z-index: 1;
}

.contact-submit input[type="submit"]:hover {
  background-color: #000;
  color: #fff;
}

.wpcf7-spinner {
  font-size: 10px;
  top: -45px;
  left: 115px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, #000 10%, white 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  z-index: 100;
}

.wpcf7-spinner::before {
  width: 50%;
  height: 50%;
  background: #000;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  display: none;
}

.wpcf7-spinner::after {
  background: #fff;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.wpcf7 form.invalid .wpcf7-response-output {
  border: none;
  color: red;
  font-weight: bold;
  text-align: center;
  margin: 60px 0;
}

.is-submit-send {
  background: #fff;
  color: transparent;
}

@media only screen and (max-width: 768px) {
  .contactBlock {
    margin: 0 0 20px;
    box-sizing: border-box;
  }
  .contactBlock iframe {
    border: none;
  }
  .contact + p + .main-container {
    padding: 0;
  }
  .contactBlock {
    padding: 0 20px;
  }
  .contactBlock dt {
    margin: 0 0 10px;
    justify-content: flex-start;
  }
  .contactBlock dt span {
    margin: 0 0 0 10px;
  }
  .contactBlock dl {
    flex-direction: column;
  }
  .contactBlock dd {
    margin: 0;
  }
  .check-wrap label span {
    white-space: nowrap;
  }
  .post-wrap a {
    left: 0;
  }
  .post-wrap input[type="text"] {
    width: 80%;
  }
  .todoufuken-wrap select {
    width: 60%;
  }
}

.blog.main_image {
  position: relative;
}

.blog.main_image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.blog.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/blog_main.jpg);
  background-position: center;
}

.blog.blog-post.main_image h2 {
  line-height: 1.5;
  text-align: center;
  writing-mode: horizontal-tb;
}

.blog-inner {
  width: 100%;
  margin: auto;
}

.blog-inner-read {
  font-size: 25px;
  text-align: center;
}

.blog-list {
  padding: 0;
  width: 100%;
  margin: auto;
}

.post-type-archive-blog,
.tax-blog_cat,
.tax-blog_tag {
  background-color: #f1f1f1;
}

.post-type-archive-blog .main-container .wrapper,
.tax-blog_cat .main-container .wrapper,
.tax-blog_tag .main-container .wrapper {
  width: 100%;
  max-width: 1920px;
}

.single-blog {
  background-color: #f1f1f1;
}

.blog-item {
  transform: 0.4s;
  width: 290px;
  margin: 0 15px 20px 0;
  list-style: none;
}

.blog-item a {
  background-color: #fff;
  height: 100%;
  text-decoration: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  transition: .4s ease;
}

.blog-item a::after {
  content: '';
  position: absolute;
  bottom: 3px;
  right: 15px;
  width: 30px;
  height: 30px;
  background-image: url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/button_arrow.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: .4s ease;
}

.blog-item a:hover {
  transform: scale(1.01);
  opacity: 0.7;
}

.blog-item a:hover::after {
  right: 10px;
}

.blog-item a:hover img {
  transform: scale(1.05);
}

.blog-item a:hover > p {
  color: #000;
  background-color: #fff;
}

.blog-item:nth-of-type(5n) {
  margin: 0 0 20px;
}

.blog-text {
  padding: 0 10px 10px;
}

.blog-text-time {
  font-size: 14px;
  margin: 5px 5px 10px;
}

.blog-sidebar {
  display: flex;
  justify-content: center;
  z-index: 20;
  position: relative;
}

.blog-sidebar a {
  text-decoration: none;
  transition: .4s ease;
}

.blog-sidebar a:hover {
  opacity: 0.7;
}

.blog-sidebar h4 {
  position: relative;
  padding: 0 0 12px 30px;
}

.blog-sidebar h4::before {
  position: absolute;
  top: -15px;
  left: -13px;
  width: 50px;
  height: 50px;
  background-image: url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/cat_icon.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 42%;
  content: '';
}

.blog-sidebar li {
  margin: 0 0 5px;
  list-style-type: none;
}

.blog-sidebar .blog-sidebar-tag h4::before {
  background-image: url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/tag_icon.png);
}

.blog-sidebar .blog-sidebar-year h4::before {
  background-image: url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/calender_icon.png);
}

.blog-sidebar .blog-sidebar-latest h4::before {
  background-image: url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/post_icon.png);
}

.blog-sidebar .blog-sidebar-latest li {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blog-sidebar-cat li,
.blog-sidebar-year li {
  position: relative;
}

.blog-sidebar-cat li a,
.blog-sidebar-year li a {
  transition: 0.4s ease;
}

.blog-sidebar-cat li a:hover,
.blog-sidebar-year li a:hover {
  opacity: 0.4;
}

.blog-sidebar-item {
  margin: 0 40px 40px;
  position: relative;
}

.blog-sidebar-item ul {
  list-style-type: none;
  padding: 20px;
  width: 500px;
  background: #939393;
  position: relative;
}

.blog-sidebar-item ul::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateY(-50%);
  top: 0px;
  border-right: 10px solid transparent;
  border-bottom: 25px solid #939393;
  border-left: 10px solid transparent;
}

.blog-sidebar-item ul li {
  list-style-type: none;
}

.blog-sidebar-item ul a {
  color: #fff;
}

.js-hide {
  display: none;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.is-hide {
  pointer-events: none;
}

.c-pagiNation__Block ul {
  justify-content: center;
  display: flex;
}

.c-pagiNation__Block li {
  list-style-type: none;
  margin: 0 10px 0 0;
}

.c-pagiNation__Block li:last-of-type {
  margin: 0;
}

.c-pagiNation__Block li a {
  text-decoration: none;
  display: inline-block;
  padding: 10px 15px;
  font-size: 18px;
  border: 1px solid #333;
  transition: 0.4s;
}

.c-pagiNation__Block li a:hover {
  color: #fff;
  background-color: #333;
}

.c-pagiNation__Block li a.next {
  color: transparent;
  border: none;
  padding: 0;
  position: relative;
}

.c-pagiNation__Block li a.next:hover {
  background-color: #f1f1f1;
}

.c-pagiNation__Block li a.next::before {
  position: absolute;
  content: '';
  left: 3px;
  top: 16px;
  width: 12px;
  height: 12px;
  border-top: 4px solid #333;
  border-right: 4px solid #333;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.c-pagiNation__Block li a.prev {
  color: transparent;
  border: none;
  padding: 0;
  position: relative;
}

.c-pagiNation__Block li a.prev:hover {
  background-color: #f1f1f1;
}

.c-pagiNation__Block li a.prev::before {
  position: absolute;
  content: '';
  right: 3px;
  top: 16px;
  width: 12px;
  height: 12px;
  border-top: 4px solid #333;
  border-right: 4px solid #333;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.c-pagiNation__Block li span {
  display: inline-block;
  padding: 10px 15px;
  font-size: 18px;
  border: 1px solid #333;
  color: #fff;
  background-color: #333;
}

.blog-content .blog-related p {
  font-size: 24px;
  font-weight: bold;
  margin: 40px 0 0;
}

.blog-content .blog-related div {
  overflow: hidden;
}

.blog-content .blog-related div img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  transition: 0.4s;
}

.blog-content .blog-related h5 {
  padding: 0 10px;
  font-size: 14px;
}

.blog-content .blog-related ul {
  padding: 0;
  display: flex;
}

.blog-content .blog-related li {
  list-style-type: none;
  width: 31.3%;
  margin: 0 3% 20px 0;
  list-style: none;
}

.blog-content .blog-related li a {
  padding: 0 0 10px;
  text-decoration: none;
  display: block;
  position: relative;
  box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.2), 0 0 5px 0 #ffffff, -4px 0 5px 0 #ffffff, -4px -6px 15px #ffffff;
  transition: .4s ease;
}

.blog-content .blog-related li a::after {
  content: '';
  position: absolute;
  bottom: 3px;
  right: 15px;
  width: 30px;
  height: 30px;
  background-image: url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/05/button_arrow.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: .4s ease;
}

.blog-content .blog-related li a:hover {
  transform: scale(1.01);
  box-shadow: 9px 14px 17px rgba(0, 0, 0, 0.2), 0 0 8px 0 #ffffff, -7px 0 8px 0 #ffffff, -7px -10px 26px #ffffff;
  opacity: 0.7;
}

.blog-content .blog-related li a:hover::after {
  right: 10px;
}

.blog-content .blog-related li a:hover img {
  transform: scale(1.05);
}

.blog-content .blog-related li a:hover > p {
  color: #000;
  background-color: #fff;
}

.blog-content .blog-related li a > p {
  margin: 15px 0px;
  border: 1px solid #000;
  transition: .4s ease;
  display: inline-block;
  padding: 0px 8px;
  color: #fff;
  background-color: #000;
}

.blog-content .blog-related li:nth-of-type(4n) {
  margin: 0 0 20px;
}

@media only screen and (max-width: 768px) {
  .c-pagiNation__Block li a {
    padding: 5px 10px;
    font-size: 16px;
  }
  .c-pagiNation__Block li a.next::before {
    top: 10px;
  }
  .c-pagiNation__Block li a.prev::before {
    top: 10px;
  }
  .c-pagiNation__Block li span {
    padding: 5px 10px;
    font-size: 16px;
  }
  .blog-block {
    flex-direction: column;
  }
  .blog-list {
    width: 100%;
  }
  .blog-sidebar {
    margin: 0;
    z-index: 105;
    width: 100%;
    max-width: none;
  }
  .blog-sidebar-item {
    margin: 0 10px 10px;
    position: static;
  }
  .blog-sidebar-item ul {
    width: 300px;
  }
  .blog-sidebar-cat ul::before {
    top: -13px;
    left: 20%;
    transform: translateY(0);
  }
  .blog-sidebar-year ul::before {
    top: -13px;
    left: auto;
    right: 14%;
    transform: translateY(0);
  }
  .blog-item {
    width: 46%;
    margin: 0 2% 15px;
  }
  .blog-item a {
    height: 100%;
    font-size: .8rem;
  }
  .blog-item:nth-of-type(5n) {
    margin: 0 2% 15px;
  }
  .blog-item:nth-of-type(3n) {
    margin: 0 2% 15px;
  }
  .blog-item:nth-of-type(2n) {
    margin: 0 2% 15px;
  }
  .blog.blog-post.main_image h2 {
    padding: 0 10px;
  }
  .blog-text-time {
    font-size: 12px;
  }
  .blog-content .blog-related ul {
    justify-content: center;
    flex-wrap: wrap;
  }
  .blog-content .blog-related li {
    width: 47.5%;
  }
  .blog-content .blog-related li:nth-of-type(2n) {
    margin: 0 0 15px;
  }
}

.post-block {
  width: 80%;
}

.post-img {
  width: 100%;
}

.post-img img {
  width: 100%;
  max-width: 100%;
}

.blog-title {
  font-size: 24px;
}

.blog-time {
  margin: 0 0 40px;
}

.blog-img {
  position: relative;
  height: 200px;
  width: 100%;
  margin: 0 0 15px;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
}

.blog-img img {
  text-align: center;
  transition: .4s ease;
}

.blog-new::before {
  z-index: 100;
  content: 'NEW';
  position: absolute;
  top: 5px;
  left: 5px;
  display: inline-block;
  margin: 0 5px;
  padding: 3px 8px;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  color: #fff;
  background-color: #f00;
}

.blog-text h3 {
  padding: 0 5px;
  font-weight: bold;
}

.blog-text p {
  line-height: 1.8;
  margin: 0 5px;
}

.blog-cat {
  color: #fff;
  border-radius: 4px;
  margin: 10px 5px 5px;
  padding: 4px 8px;
  font-size: 11px;
  background-color: #959595;
  display: inline-block;
}

.blog-tag,
.blog-sidebar-tag {
  padding: 0;
}

.blog-tag li,
.blog-sidebar-tag li {
  color: #fff;
  border-radius: 10px;
  margin: 0 5px 5px 0;
  list-style: none;
  display: inline-block;
  font-size: 12px;
  padding: 5px;
  background-color: #565656;
}

.blog-sidebar-tag ul {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
}

.blog-sidebar-tag li {
  transition: 0.4s ease;
}

.blog-sidebar-tag li:hover {
  opacity: 0.7;
}

.blog-author {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  border: 2px solid #333;
  padding: 20px;
}

.blog-author-img {
  overflow: hidden;
  min-width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #333;
}

.blog-author-img img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.blog-author-text {
  margin: 0 0 0 20px;
}

.blog-author-text-name {
  border-left: 4px solid #333;
  padding: 0 0 0 15px;
  font-size: 20px;
  font-weight: bold;
}

.blog-content {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  font-family: "ヒラギノ明朝 Pro" , "Hiragino Mincho Pro" , "HiraMinProN-W3" ,"游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "HGS明朝E" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
}

.blog-content strong {
  font-weight: bold;
  font-size: 110%;
}

.blog-content h4 {
  margin: 60px 0 20px;
  border-left: 4px solid #333;
  padding: 0 0 0 20px;
  font-size: 24px;
}

.blog-content p {
  word-break: break-all;
  word-wrap: break-word;
  font-size: 16px;
}

.blog-content p a {
  transition: 0.4s ease;
}

.blog-content p a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.blog-content .js-date {
  font-size: 150%;
  font-weight: bold;
  padding: 0 5px;
}

@media only screen and (max-width: 768px) {
  .blog-content h4 {
    font-size: 18px;
  }
  .blog-content p {
    font-size: 14px;
  }
  .blog-inner {
    width: 100%;
  }
  .blog-author {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .blog-author-text-name {
    font-size: 18px;
  }
  .blog-author-img {
    width: 150px;
    min-width: 150px;
    height: 150px;
    margin: 0 0 10px;
  }
  .blog-author-text {
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  #detailPhoto .thumb li {
    margin: 0 15px;
  }
}

.footer-container footer .cv a.footerContactBt {
  line-height: 1;
  padding: 15px 15px;
  background-color: #ec7522;
  font-weight: bold;
  font-size: 16px;
  transition: 0.4s ease;
}

.footer-container footer .cv a.footerContactBt:hover {
  opacity: 0.7;
  background-color: #ec7522;
}

.grecaptcha-badge {
  visibility: hidden;
  bottom: 95px !important;
}

.page-id-21 .grecaptcha-badge {
  visibility: visible;
}

.productContents ul {
  justify-content: flex-start;
}

.productContents ul li {
  margin: 0 5% 2.8em 0;
}

.productContents ul li:nth-of-type(3n) {
  margin: 0 0 2.8em;
}

@media only screen and (min-width: 640px) {
  .productContents ul li {
    width: 30%;
  }
}

@media only screen and (max-width: 639px) {
  .productContents ul {
    justify-content: center;
  }
  .productContents ul li {
    margin: 0 2% 1.5em 0;
  }
  .productContents ul li:nth-of-type(3n) {
    margin: 0 2% 1.5em 0;
  }
  .productContents ul li:nth-of-type(2n) {
    margin: 0 0 1.5em;
  }
  .productContents ul li:nth-of-type(6n) {
    margin: 0 0 1.5em;
  }
}

.zoshigaya.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/11/zoshigaya_main.jpg);
}

.zoshigayaBlock {
  max-width: 900px;
  margin: 5em auto 5em;
}

.zoshigayaBlock-map {
  position: relative;
  margin: 0 0 120px;
}

.zoshigayaBlock-map img {
  width: 100%;
  max-width: 100%;
}

.zoshigaya-item {
  width: 210px;
  position: absolute;
  top: 0;
  left: auto;
  bottom: auto;
  right: auto;
}

.zoshigaya-item .js-modal {
  transition: .4s ease;
  border: none;
  background: transparent;
}

.zoshigaya-item .js-modal:hover {
  transform: scale(1.05);
}

.zoshigaya-item img {
  width: 100%;
  max-width: 100%;
}

.kishimojin {
  margin-top: 170px;
  margin-left: 36px;
}

.chitose {
  margin-top: 383px;
  margin-left: 36px;
}

.reien {
  margin-top: 140px;
  margin-left: 665px;
}

@media only screen and (max-width: 1139px) {
  .zoshigaya-item {
    width: 23%;
  }
  .kishimojin {
    margin-top: 18%;
    margin-left: 4%;
  }
  .chitose {
    margin-top: 43%;
    margin-left: 4%;
  }
  .reien {
    margin-top: 15%;
    margin-left: 74%;
  }
}

.youtube-wrap {
  position: relative;
  padding: 0 0 56%;
}

.youtube {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.c-modal {
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  display: none;
  z-index: 9999;
  position: fixed;
  top: -10px;
  right: 0;
  bottom: -10px;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.45);
}

.c-modal__container {
  display: table;
  width: 100%;
  height: 100%;
  padding: 10px 0;
}

.c-modal__inner {
  display: table-cell;
  padding: 2.7em 2em;
  vertical-align: middle;
}

.c-modal__contents {
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 1300px;
  margin: 0 auto;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  text-justify: inter-ideograph;
}

.c-modal__contents::after {
  display: table;
  clear: both;
  content: '';
}

.c-modal__contents > p {
  line-height: 1.7;
  text-indent: 1em;
}

.c-modal__button__wrap {
  position: absolute;
  top: 20px;
  right: 20px;
}

.c-modal__button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  background: transparent;
  line-height: 1;
  text-indent: -9999px;
  vertical-align: middle;
  cursor: pointer;
}

.c-modal__button::before {
  position: absolute;
  top: -45px;
  left: 10px;
  width: 35px;
  height: 3px;
  transform: rotate(45deg);
  background-color: #fff;
  content: '';
  cursor: pointer;
}

.c-modal__button::after {
  position: absolute;
  top: -45px;
  left: 10px;
  width: 35px;
  height: 3px;
  transform: rotate(-45deg);
  background-color: #fff;
  content: '';
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .zoshigayaBlock {
    margin: 3em auto 3em;
  }
  .zoshigayaBlock-map {
    margin: 40px 0 0;
  }
  .zoshigayaBlock-map > img {
    display: none;
  }
  .zoshigaya-item {
    width: 80%;
    position: static;
    margin: 0 auto 20px;
  }
  .c-modal__contents {
    padding: 0;
  }
  .c-modal__inner {
    padding: 2.7em 1em;
  }
}

.movieBlock {
  max-width: 900px;
  margin: 5em auto 5em;
}

.movie-read {
  text-align: center;
  margin: 0 0 30px;
  font-size: 22px;
}

.movie.main_image {
  background-image: url(/img/main_bg@2x.gif), url(https://jomo-p.co.jp/cp/wp/wp-content/uploads/2022/11/movie_main.jpg);
}

.movie-list {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  margin: 0 0 40px;
  padding: 0;
}

.movie-item {
  width: 47.5%;
  margin: 0 4% 40px 0;
}

.movie-item h3 {
  font-size: 20px;
  margin: 0 0 10px;
}

.movie-item > button {
  border: none;
  padding: 0;
  background: transparent;
}

.movie-item:nth-of-type(2n) {
  margin: 0 0 40px;
}

.movie-item img {
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
}

.topContents-link-movie {
  margin: 0 auto 100px;
}

@media only screen and (max-width: 500px) {
  .page-id-1088 .breadcrumb {
    margin: 15px auto 15px 0px;
  }
  .movieBlock {
    margin: 3em auto 3em;
  }
  .movieBlock h3 {
    font-size: 18px;
  }
  .topContents-link-movie {
    margin: 0 auto 60px;
  }
  .movie-read {
    margin: 0 0 20px;
    font-size: 16px;
  }
  .movie-list {
    padding: 0;
  }
  .movie-item {
    margin: 0 2% 10px 0;
  }
  .movie-item h3 {
    font-size: 16px;
  }
  .movie-item:nth-of-type(2n) {
    margin: 0 0 10px;
  }
}

.c-cookie__box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  display: flex;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 40px;
  background: rgba(0, 0, 0, 0.8);
}

.c-cookie__box .c-container.en {
  display: none;
}

.c-cookie__box .c-container {
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.c-cookie__text {
  width: 75%;
  margin: 0 60px 0 0;
  color: #fff;
  font-size: 13px;
  line-height: 1.75;
}

.c-cookie__btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.c-cookie__btn--consent a,
.c-cookie__btn--detail a {
  display: block;
  padding: 10px;
  background-color: #ababab;
  color: #fff;
  font-size: 13px;
  -webkit-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}

.c-cookie__btn--consent a:hover,
.c-cookie__btn--detail a:hover {
  opacity: 0.7;
}

.c-cookie__btn--consent {
  margin: 0 25px 0 0;
}

.c-cookie__btn--consent a {
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 145px;
  height: 48px;
  background-color: #fff;
  color: #323030;
  box-sizing: border-box;
  text-decoration: none;
}

.c-cookie__btn--detail a {
  box-sizing: border-box;
  display: -webkit-flex;
  display: -ms-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 145px;
  text-decoration: none;
  height: 48px;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .c-cookie__box {
    padding: 10px;
  }
  .c-cookie__box .c-container {
    flex-direction: column;
  }
  .c-cookie__text {
    width: 100%;
    margin: 0 20px;
    font-size: 12px;
  }
  .c-cookie__btn--consent a,
  .c-cookie__btn--detail a {
    width: 140px;
    height: 40px;
  }
}
